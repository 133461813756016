import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query'
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { initializeFirestore, CACHE_SIZE_UNLIMITED, enableIndexedDbPersistence, getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserLocalPersistence, connectAuthEmulator } from "firebase/auth";
import { getStorage } from "firebase/storage";
import dbContext from './utils/dbContext';
import { AuthContextProvider } from './utils/AuthContext';
import StorageContext from './utils/StorageContext';
import AppContext, { AppContextProvider } from './utils/AppContext';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2Hsx2axuvvgROA_KmCL5GSUIutOPlx00",
  authDomain: "moldinspector-c0a0c.firebaseapp.com",
  projectId: "moldinspector-c0a0c",
  storageBucket: "moldinspector-c0a0c.appspot.com",
  messagingSenderId: "1004901556480",
  appId: "1:1004901556480:web:4d31d4c9ebdd66048613e5",
  measurementId: "G-YE1FBRJ6JE"
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //refetchOnWindowFocus: false,
    }
  }
})

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)

const db = getFirestore(app)
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

const storage = getStorage(app);


ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <AuthContextProvider auth={auth} >
        <QueryClientProvider client={queryClient}>
          <dbContext.Provider value={db}>
            <StorageContext.Provider value={storage}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </StorageContext.Provider>
          </dbContext.Provider>
        </QueryClientProvider>
      </AuthContextProvider>
    </AppContextProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
