import React, { useContext, useEffect } from 'react';
import { ref, listAll, getDownloadURL } from "firebase/storage";
import StorageContext from '../../utils/StorageContext';
import AppContext from '../../utils/AppContext';
import { styled } from '@mui/material/styles';
import { PaperClipIcon } from '@heroicons/react/outline';
import { useQuery } from 'react-query'
import { getDoc, doc } from 'firebase/firestore';
import { Navigate, useNavigate, useParams, Link } from 'react-router-dom';
import dbContext from '../../utils/dbContext';
import Button from '../../components/Button'
import Observations from '../../components/Observations'
import RoomImages from '../../components/RoomImages'

const RoomDetails = () => {
    const { inspectionID, roomID } = useParams();
    const navigate = useNavigate()
    const db = useContext(dbContext)

    const { isLoading, data, error } = useQuery(['RoomDetails',roomID], async () => {
        const docRef = doc(db, "Rooms",roomID)
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            return docSnap.data()
        } else {
            console.log("No such document!");
            return null;
        }
    })

    console.log(data)

    return <div className='container mx-auto'>
        <div className='block'>
            <nav className="flex" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3 py-2">
                    <li className="inline-flex items-center">
                    <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                        <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                        inspections
                    </Link>
                    </li>
                    <li className="inline-flex items-center">
                    <Link to={`/Inspections/${inspectionID}`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        {inspectionID}
                    </Link>
                    </li>
                    <li className="inline-flex items-center">
                    <Link to={`/Inspections/${inspectionID}`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        Rooms
                    </Link>
                    </li>
                    <li aria-current="page">
                    <div className="flex items-center">
                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{data && `${roomID} (${data.Name})`}</span>
                    </div>
                    </li>
                </ol>
            </nav>

            {data && <RoomImages Title="Images" images={data.images} />}
            {data && <Observations data={data.observations} title="Observations"/>}
            
            <Button className="my-2">New Image</Button>
        </div>    
    </div>
}

export default RoomDetails