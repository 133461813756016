import React,{createContext, useEffect, useState} from 'react';

const AppContext = createContext({
    onlineStatus: {},
    setOnlineStatus: () => {},
    mode: {},
    setMode: () => {}
});

export const AppContextProvider = (props) => {
    const [online, setOnline] = useState(window.navigator.onLine)
    const [mode, setMode] = useState(localStorage.getItem('color-theme'))

    useEffect(() => {
        if(mode === 'dark')
            document.documentElement.classList.add('dark');
        else if (mode === 'light')
            document.documentElement.classList.remove('dark')
    },[mode])

    const modeHandler = (mode) => {
        localStorage.setItem('color-theme', mode)
        setMode(mode)
    }

    const contextValue = {
        onlineStatus: online,
        setOnlineStatus: setOnline,
        mode: mode,
        setMode: modeHandler
    }

    return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>
}

export default AppContext