import React, {useContext, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../utils/AuthContext";
import {Snackbar, Alert, Toolbar, Box, Avatar, Typography, TextField, Button} from '@mui/material'
import { LockOutlined } from "@mui/icons-material";

const Login = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const authCtx = useContext(AuthContext)

    const [username, setUserName] = useState(null)
    const [password, setPassword] = useState(null)
    const [showLoginToast, setShowLoginToast] = useState(false)
    const [toastLoginBody, setToastLoginBody] = useState('')

    let from = location.state?.from?.pathname || "/";

    function handleSubmit(event) {
        event.preventDefault();

        authCtx.login(username, password,() => navigate(from, { replace: true }))
    }

    return ( <>
        <Toolbar />
        <Box
             sx={{
               marginTop: 8,
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
             }}
           >
             <Avatar sx={{ m: 1, bgcolor: 'iconColor.main' }}>
               <LockOutlined />
             </Avatar>
             <Typography component="h1" variant="h5">
               Sign in
             </Typography>
             <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
               <TextField
                 margin="normal"
                 required
                 fullWidth
                 id="username"
                 label="User Name"
                 name="username"
                 autoComplete="username"
                 value={username}
                 onChange={(e) => setUserName(e.target.value)}
                 autoFocus
               />
               <TextField
                 margin="normal"
                 required
                 fullWidth
                 name="password"
                 label="Password"
                 type="password"
                 id="password"
                 value={password}
                 autoComplete="current-password"
                 onChange={(e) => setPassword(e.target.value)}
               />
               <Button
                 type="submit"
                 fullWidth
                 variant="contained"
                 sx={{ mt: 3, mb: 2 }}
               >
                 Sign In
               </Button>
             </Box>
           </Box>
           <Snackbar
               open={showLoginToast}
               autoHideDuration={6000}
               onClose={() => {setShowLoginToast(false);setToastLoginBody('')}}
           >
             <Alert severity="error" sx={{ width: '100%' }}>{toastLoginBody}</Alert>
           </Snackbar>
       </>
          
       )

}

export default Login