import React from "react";

const Button = (props) => {
    return (
        <button className={`
        border 
        focus:outline-none 
        focus:ring-4 
        font-medium 
        rounded-lg 
        text-sm 
        px-5 
        py-2.5 
        mr-2 
        mb-2 
        bg-indigo-500 
        text-white 
        border-gray-600 
        hover:bg-indigo-700 
        hover:border-gray-600  ${props.className}`}
        
        onClick={props.onClick}>{props.children}</button>
    )
}

export default Button