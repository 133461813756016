import React,{createContext, useState, useEffect} from 'react';
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";

const AuthContext = createContext({
    user: {},
    isLoading: true,
    login: (email, password, callback) => {},
    logout: (callback) => {}
});

export const AuthContextProvider = (props) => {
    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        onAuthStateChanged(props.auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setUser(user)
                setIsLoading(false)
                // ...
            } else {
                setIsLoading(false)
                setUser(null)
            }
        });
    }, []);

    const loginHandler = (email, password, callback) => {
        signInWithEmailAndPassword(props.auth, email, password)
            .then((userCredential) => {
            // Signed in 
                setUser(userCredential.user)
                callback();
            // ...
            })
            .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            });
    };

    const logoutHandler = (callback) => {
        signOut(props.auth).then(() => {
            setUser(null)
            callback();
          }).catch((error) => {
            // An error happened.
          });
    }  


    const contextValue = {
        user: user,
        isLoading: isLoading,
        login: loginHandler,
        logout: logoutHandler,
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext