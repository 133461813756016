import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './routes/Main'
import Home from './routes/Home'
import Login from './routes/Login';
import NewInspection from './routes/Inspection/NewInspection'
import InspectionDetails from './routes/Inspection/InspectionDetails';
import RoomDetails from './routes/Inspection/RoomDetails';
import AuthContext from './utils/AuthContext';
import RequireAuth from './utils/RequireAuth';
import AppContext from './utils/AppContext';

const App = () => {
  const authCtx = useContext(AuthContext)
  const appCtx = useContext(AppContext)

  useEffect(() => {
    window.addEventListener('offline', () => appCtx.setOnlineStatus(false));
    window.addEventListener('online', () => appCtx.setOnlineStatus(true));
  },[])

  return (
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          } />
          <Route path='/Inspections/New' element={
            <RequireAuth>
              <NewInspection />
            </RequireAuth>
          }
          />
          <Route path='/Inspections/:inspectionID' element={
            <RequireAuth>
              <InspectionDetails />
            </RequireAuth>
          }
          />
          <Route path='/Inspections/:inspectionID/Rooms/:roomID' element={
            <RequireAuth>
              <RoomDetails />
            </RequireAuth>
          }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Route>
      </Routes>
  );
}

export default App;
