import React, { useContext , Suspense} from "react";
import { useQuery } from "react-query";
import dbContext from "../utils/dbContext";
import { collection, query, getDocs } from "firebase/firestore";
import {ref, getDownloadURL, listAll, getBlob  } from "firebase/storage";
import { LinearProgress, Skeleton, Container, } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import StorageContext from "../utils/StorageContext";
import {Link} from 'react-router-dom'
import { useState } from "react";

const LazyCard = React.lazy(() => import('../components/Card'))

const Home = () => {
    const db = useContext(dbContext);
    const storage = useContext(StorageContext)
    const navigate = useNavigate()
    const [reportsWImages, setReportsWImages] = useState([])

    const { isLoading, data: reports, error } = useQuery('getReports', async () => {
        const ReportsRef = collection(db, "Reports");
        const q = query(ReportsRef);
        const querySnapshot = await getDocs(q);
        let reports = []
        querySnapshot.forEach((doc) => {
            reports.push({ ...doc.data(), id: doc.id })
        });
        console.log(reports)
        return reports
    })

    // const {isLoadingImages, data: images, errorImages } = useQuery('getImages', async () => {
    //     const listRef = ref(storage, 'images')
    //     let res = await listAll(listRef)
    //     console.log(res)
    //     let tasks = res.prefixes.map(async (folderRef) => {
    //         let id = folderRef.name
    //         let test = ref(folderRef, 'ExternalImage')
    //         try{
    //             let image = await getDownloadURL(test)
    //             return {id: id, image: image}
    //         }catch{
    //             return {id: id, image: null}
    //         }
    //     });
    //     const images = await Promise.all(tasks)
    //     return images
    // }, {
    //     enabled: !!reports,
    //     cacheTime: Infinity,
    //     staleTime: Infinity,
    //     onSuccess: data => {
    //         let mappedData = reports.map((item) => ({...item, image: data}));
    //         console.log(mappedData)
    //         setReportsWImages(mappedData)
    //     }
        
    // })

    if (isLoading) return <LinearProgress />

    return <>
        <div className='container mx-auto font-sans'>
            <div className="flex flex-wrap gap-4">
            <Link to="/Inspections/New" class="w-full sm:w-auto hover:border-blue-500 px-24 hover:border-solid hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 hover:text-blue-500 group flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 dark:text-slate-100 text-slate-900 font-medium py-3">
                <svg class="group-hover:text-blue-500 mb-1 text-slate-400" width="20" height="20" fill="currentColor" aria-hidden="true">
                <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                </svg>
                New Report
            </Link>
                {reports && reports.map((item, index) => (
                    <Suspense fallback={<Skeleton variant="rectangular" width={210} height={118} />}>
                        <LazyCard key={index} report={item}/>
                    </Suspense>
                    
                ))}
            </div>
        </div>

    </>
}

export default Home