
import IconButton from '@mui/material/IconButton';
import { PhotoCamera, CalendarMonth } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { doc, setDoc, } from "firebase/firestore";
import dbContext from '../../utils/dbContext';
import { useMutation, useQueryClient } from 'react-query';
import { styled } from '@mui/material/styles';
import imageCompression from 'browser-image-compression';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import StorageContext from '../../utils/StorageContext';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Button from '../../components/Button';
import { useRef } from 'react';
import {Link} from 'react-router-dom'

// Import the functions you need from the SDKs you need

const Input = styled('input')({
    display: 'none',
});

const NewInspection = () => {
    const storage = useContext(StorageContext)
    const db = useContext(dbContext)
    const navigate = useNavigate()
    const [preview, setPreview] = useState()
    const datepicker = useRef(null)
    const queryClient = useQueryClient()
    const { handleSubmit, register, watch, control, formState: { errors } } = useForm();


    const imageMutation = useMutation(async data => {
        const image = await imageCompression(data.externalImage[0], {
            maxSizeMB: 1,
            useWebWorker: true
        })
        console.log(data)
        const storageRef = ref(storage, `images/${data.ReportNumber}/ExternalImage`);
        await uploadBytes(storageRef, image)
        const url = await getDownloadURL(storageRef);
        return url
    },
    {
        onError: (error, variables, context) => {
            // An error happened!
            toast.error(`Error: ${error}`);
        },
        onSuccess: (data, variables, context) => {
            let formdata = watch()
            dataMutation.mutate({...formdata, url:data})
            queryClient.invalidateQueries('getReports')
            toast.success('Successfully Uploaded Image!');
        },
    }, {
        retry: false
    })

        const dataMutation = useMutation(async (data) => {   
            await setDoc(doc(db, "Reports", data.ReportNumber), {
                Address: data.Address,
                AmbientHumidity: data.AmbientHumidity,
                AmbientTemperature: data.AmbientTemperature,
                //ClientPresent: data.ClientPresent,
                Date: data.Date,
                ExternalImage: data.url || '',
                IntroNotes: data.IntroNotes
            });
            return true
        },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                    toast.error(`Error: ${error}`);
                },
                onSuccess: (data, variables, context) => {
                    toast.success('Successfully created!');
                    queryClient.invalidateQueries('getReports')
                    setTimeout(() => {
                        navigate('/')
                    },[500])
                },
            })

    const watchExternalImage = watch("externalImage");
    const externalImage = register('externalImage', { required: true })
    console.log(watch())

    const onSubmit = async data => {
        let test = dataMutation.mutateAsync(data)
        if(test){
            await imageMutation.mutateAsync(data)
        }
    }

    useEffect(() => {
        const test = async () => {
            if (watchExternalImage?.length < 1 || !watchExternalImage) {
                setPreview(undefined)
                return
            }

            const objectUrl = URL.createObjectURL(watchExternalImage[0])
            setPreview(objectUrl)

            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)
        }
        test();
    }, [watchExternalImage])

    return (
        <div className='container mx-auto'>
            <nav class="flex" aria-label="Breadcrumb">
                <ol class="inline-flex items-center space-x-1 md:space-x-3 py-2">
                    <li class="inline-flex items-center">
                    <Link to="/" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                        inspections
                    </Link>
                    </li>
                    <li aria-current="page">
                    <div class="flex items-center">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">New Inspection</span>
                    </div>
                    </li>
                </ol>
            </nav>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-1  sm:grid-cols-2 gap-4 justify-center m-w-xl p-4 sm:m-w-lg bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 '>
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="text" {...register("ReportNumber")} className="block py-2.5 px-0 w-full text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="floating_ReportNumber" className="absolute text-xl text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Report Number</label>
                    </div>
                    <div className="relative z-0 mb-6 w-full group ">
                        <input type="text" {...register("Address")} className="block py-2.5 px-0 w-full text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label for="floating_Address" className="absolute text-xl text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>
                    </div>
                    <div className='flex flex-wrap'>
                        <div className="p-4 w-1/2">
                            <div className='relative z-0 mb-6 group'>
                                <input type="number" {...register("AmbientTemperature")} className="block py-2.5 px-0 w-full text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                <label for="floating_ATemperature" className="absolute text-xl text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Ambient Temp</label>
                            </div>
                        </div>
                        <div className="p-4 w-1/2">
                            <div className='relative z-0 mb-6 group '>
                                <input type="number" {...register("AmbientHumidity")} className="block py-2.5 px-0 w-full text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                <label htmlFor="floating_AmbientHumidity" className="absolute text-xl text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Ambient Humidity</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="upload-image">
                            <Input accept="image/*" {...externalImage} id="upload-image" type="file" capture="environment" />
                            <IconButton color="primary" aria-label="upload picture" component="span">
                                <PhotoCamera />
                            </IconButton>
                        </label>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <textarea
                            {...register("IntroNotes")}
                            id="about"
                            rows={3}
                            className="shadow-sm focus:ring-indigo-500 resize-none text-lg dark:text-white p-2  dark:bg-gray-800 mt-1 block w-full border dark:border-gray-600 border-gray-300 rounded-md"
                            placeholder="Intro notes"
                            defaultValue={''}
                        />
                    </div>
                    <div>
                        <input type="date" className='text-gray-900 rounded-md dark:bg-slate-600 dark:text-slate-100' {...register("Date")}/>
                    </div>
                    {watchExternalImage && <img src={preview} />}
                    <div>
                        <Button className="m-w-sm" type="submit">Submit</Button>
                    </div>

                </div>
            </form>


            <Toaster />
        </div>
    );
}

export default NewInspection;

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});