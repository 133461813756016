import React from "react";
import { Link } from "react-router-dom";

const Observations = (props) => {

    return <React.Fragment>
        <div className='block mt-2 p-2 w-full  bg-white dark:bg-slate-800 dark:border-gray-700 rounded-t-lg border fbg-slate-50 shadow-md dark:divide-slate-700 divide-grey-700'>
            <h2 className='text-3xl text-slate-900 font-md dark:text-slate-200  '>
                {props.title}
            </h2>
        </div>
        <div className='flex flex-row gap-4 p-2 w-full bg-white dark:bg-slate-800 dark:border-gray-700 border fbg-slate-50 shadow-md dark:divide-slate-700 divide-grey-700'>
        <Link to="/Rooms/New" class="max-w-sm hover:border-blue-500 px-16 sm:px-24 md:px-32 hover:border-solid mx-2 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 hover:text-blue-500 group flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 dark:text-slate-100 text-slate-900 font-medium py-3">
            <svg class="group-hover:text-blue-500 mb-1 text-slate-400" width="20" height="20" fill="currentColor" aria-hidden="true">
            <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
            </svg>
            New Observation
        </Link>
        {props.data.map((item) => (
            <div className='flex flex-col'>
                <h3 class="mt-2 p-2 font-md text-2xl dark:text-slate-400">
                    {item.Name}
                </h3>
            </div>
        ))}
        </div>
    </React.Fragment>  

}

export default Observations