import React from 'react';
// Import the functions you need from the SDKs you need
import AppBar from '../components/AppBar';
import { Outlet, Routes } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { createTheme, Box, CssBaseline } from '@mui/material';

const Main = () => {

  return (
    <div className='bg-slate-200 dark:bg-slate-900 min-h-screen'>
     <AppBar />
      <Box component="main" sx={{ flex: 1, py: 6, px: 4 }}>
        <Outlet/>
      </Box>
    </div>
     
  );
}

export default Main;
