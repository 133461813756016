import React, { useContext, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { ref, listAll, getDownloadURL } from "firebase/storage";
import StorageContext from '../../utils/StorageContext';
import AppContext from '../../utils/AppContext';
import { styled } from '@mui/material/styles';
import { PaperClipIcon } from '@heroicons/react/outline';
import { useQuery } from 'react-query'
import { getDoc, doc } from 'firebase/firestore';
import { Navigate, useNavigate, useParams, Link } from 'react-router-dom';
import dbContext from '../../utils/dbContext';
import Button from '../../components/Button'
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from 'file-saver';

const Input = styled('input')({
    display: 'none',
});

const InspectionDetails = () => {
    const { inspectionID: id } = useParams();
    const navigate = useNavigate()
    const storage = useContext(StorageContext)
    const db = useContext(dbContext)
    const appCtx = useContext(AppContext)

    const { isLoading, data, error } = useQuery(['InspectionDetails',id], async () => {
        const docRef = doc(db, "Reports", id)
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            return docSnap.data()
        } else {
            console.log("No such document!");
            return null;
        }
    })

    console.log('render')

    const handleGenerate = () => {
        const doc = new Document({
            sections: [{
                properties: {},
                children: [
                    new Paragraph({
                        children: [
                            new TextRun("DATA"),
                        ],
                    }),
                ],
            }],
        });

        Packer.toBlob(doc).then((blob) => {
            // saveAs from FileSaver will download the file
            saveAs(blob, "example.docx");
        });
    }

    // useEffect(() => {
    //     if (appCtx.onlineStatus) {
    //         listAll(imagesRef)
    //             .then((res) => {
    //                 res.prefixes.forEach((folderRef) => {
    //                     console.log(folderRef)
    //                 });
    //                 res.items.forEach((itemRef) => {
    //                     getDownloadURL(itemRef).then((result) => console.log(result))
    //                 });
    //             }).catch((error) => {
    //                 // Uh-oh, an error occurred!
    //             });
    //     }

    // }, [])

    if(isLoading) return "loading"


    return (
        <div className='container mx-auto'>
            <div className='block'>
                <nav class="flex" aria-label="Breadcrumb">
                    <ol class="inline-flex items-center space-x-1 md:space-x-3 py-2">
                        <li class="inline-flex items-center">
                        <Link to="/" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                            <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                            inspections
                        </Link>
                        </li>
                        <li aria-current="page">
                        <div class="flex items-center">
                            <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                            <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{id}</span>
                        </div>
                        </li>
                    </ol>
                </nav>
                <div className='grid grid-cols-1 p-4  sm:grid-cols-4 gap-4 justify-center bg-white rounded-lg border border-gray-200 shadow-md dark:bg-slate-800 dark:border-gray-700'>
                    <div className='grow'>
                        <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>Date</h5>
                        <p className='text-base text-gray-900 dark:text-white'>{data.Date.toDate}</p>
                    </div>
                    <div className='grow'>
                        <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>Address</h5>
                        <p className='text-base text-gray-900 dark:text-white'>{data.Address}</p>
                    </div>
                    <div className='grow'>
                        <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>Humidity</h5>
                        <p className='text-base text-gray-900 dark:text-white'>{data.AmbientHumidity}</p>
                    </div>
                    <div className='grow'>
                        <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>Weather Condition</h5>
                        <p className='text-base text-gray-900 dark:text-white'>{data.WeatherCondition}</p>
                    </div>
                    <div className='grow'>
                        <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>Property Type</h5>
                        <p className='text-base text-gray-900 dark:text-white'>{data.PropertyType}</p>
                    </div>
                </div>
                <div className='block mt-2 p-2 w-full bg-white dark:bg-slate-800 dark:border-gray-700 rounded-t-lg border fbg-slate-50 shadow-md dark:divide-slate-700 divide-grey-700'>
                    <h2 className='text-3xl text-slate-900 font-md dark:text-slate-200  '>
                        Rooms
                    </h2>
                </div>
                <div className='flex flex-row gap-4 p-2 w-full bg-white dark:bg-slate-800 dark:border-gray-700 border fbg-slate-50 shadow-md dark:divide-slate-700 divide-grey-700'>
                <Link to="/Rooms/New" class="h-60 w-full sm:w-1/2 md:w-1/4 object-cover rounded-t-md hover:border-blue-500 hover:border-solid hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 hover:text-blue-500 group flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 dark:text-slate-100 text-slate-900 font-medium">
                    <svg class="group-hover:text-blue-500 mb-1 text-slate-400" width="20" height="20" fill="currentColor" aria-hidden="true">
                    <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                    </svg>
                    New Room
                </Link>
                {data && data?.Rooms.map((item) => (
                    <Link to={`/Inspections/${id}/Rooms/${item.id}`} class="h-60 w-full sm:w-1/2 md:w-1/4 hover:border-blue-500 hover:border-solid  hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 hover:text-blue-500 group flex flex-col items-center justify-start rounded-md border-2 border-slate-300 text-sm leading-6 dark:text-slate-100 text-slate-900 font-medium">
                    <img src={item.selectedImage} alt="" class="h-44 w-full object-cover rounded-t-md" loading='lazy'/>
                    <div className='flex flex-col'>
                    <h3 class="mt-2 p-2 font-md text-2xl dark:text-slate-400">
                        {item.Name}
                    </h3>
                    </div>
                </Link>
                ))}
                   
                </div>
                <Button className="my-2" onClick={handleGenerate}>Generate Report</Button>
            </div>
            
        </div>
    )
    

    // if (isLoading) return <LinearProgress />

    // return (
    //     <>
    //         <Breadcrumbs aria-label="breadcrumb">
    //             <Link underline="none" color="inherit" to="/">
    //                 Inspections
    //             </Link>
    //             <Typography color="text.primary">{`${id}`}</Typography>
    //         </Breadcrumbs>
    //         <p>
    //             {JSON.stringify(data)}
    //         </p>
    //         {data && <Box sx={{ maxWidth: 500 }} component='img' src={data.ExternalImage} />}
    //         <Button sx={{ m: 2 }} variant="contained" onClick={() => navigate(`/Inspections/${id}/Rooms`)}>Go To Rooms</Button>
    //     </>
    // );
}

export default InspectionDetails;
