import React from "react";
import { Link } from "react-router-dom";

const RoomImages = ({images, Title}) => {

    return <React.Fragment>
        <div className='block mt-2 p-2 w-full bg-white dark:bg-slate-800 dark:border-gray-700 rounded-t-lg border fbg-slate-50 shadow-md dark:divide-slate-700 divide-grey-700'>
            <h2 className='text-3xl text-slate-900 font-md dark:text-slate-200  '>
                {Title}
            </h2>
        </div>
        <div className='flex flex-row flex-wrap gap-4 p-2 w-full bg-white dark:bg-slate-800 dark:border-gray-700 border fbg-slate-50 shadow-md dark:divide-slate-700 divide-grey-700'>
            <Link to="/Rooms/New" class="h-60 w-full sm:w-1/2 md:w-1/4 object-cover rounded-t-md hover:border-blue-500 hover:border-solid hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 hover:text-blue-500 group flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 dark:text-slate-100 text-slate-900 font-medium">
                <svg class="group-hover:text-blue-500 mb-1 text-slate-400" width="20" height="20" fill="currentColor" aria-hidden="true">
                    <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                </svg>
                New Image
            </Link>
            {images.map((item) => (
                <>
                    <img src={item} alt="" class="sm:h-60 w-full sm:w-1/2 md:w-1/4 object-cover rounded-t-md" loading='lazy' />
                </>
            ))}
        </div>
    </React.Fragment>

}

export default RoomImages