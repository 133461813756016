import React, { useContext, Fragment} from 'react';
import AuthContext from '../utils/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import AppContext from '../utils/AppContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AppBar = () => {
    const authCtx = useContext(AuthContext)
    const appCtx = useContext(AppContext)
    const navigate = useNavigate()

    const toggleMode = () => {
      if(appCtx.mode === 'dark')
        appCtx.setMode('light')
      else
        appCtx.setMode('dark')
    }

    return (
        <Disclosure as="nav" className="bg-gray-800 border-b-2 shadow-md border-slate-900/10">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <h1 className='text-white text-2xl'>Mold Inspector</h1>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    
                      <Link
                      to="/"
                         key='Inspections'
                         as="a"
                         className={classNames('hover:cursor-pointer text-gray-300 hover:bg-gray-700 hover:text-white',
                           'block px-3 py-2 rounded-md text-base font-medium'
                         )}
                         aria-current={true ? 'page' : undefined}
                       >
                         Inspections
                      </Link>
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                {/* Profile dropdown */}
                <button onClick={toggleMode} className='mr-2'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 stroke-white fill-white dark:fill-black dark:stroke-black" viewBox="0 0 24 24" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                </svg>
                </button>
                {authCtx.user &&<Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://scontent.fbtr1-1.fna.fbcdn.net/v/t39.30808-6/277773938_10219147215448012_1292182249649601075_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=-1XS88scDQMAX8wXdCZ&_nc_ht=scontent.fbtr1-1.fna&oh=00_AT9TbMx0Q_Mdcx6A6JXgQEgKy6wqbWTGJmMaXMBJ1IZ4Hg&oe=626A7758"
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <button onClick={() => authCtx.logout(() => navigate('/'))}
                            className={classNames(active ? 'bg-gray-100' : '', 'w-full block px-4 py-2 text-sm text-gray-700')}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>}

              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
                <Disclosure.Button
                  key='Inspections'
                  as="a"
                  onClick={() => navigate('/')}
                  className={classNames(
                    'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={true ? 'page' : undefined}
                >
                  Inspections
                </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>

    );
}

export default AppBar