import React, { useContext } from "react";
import AuthContext from "./AuthContext";
import { useLocation, Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
    const authCtx = useContext(AuthContext)
    let location = useLocation();

    if(authCtx.isLoading) return <div>Loading...</div>
    if (!authCtx.user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

export default RequireAuth